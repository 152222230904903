import {
  ALLOWED_CURRENCIES_FOR_DEPOSIT,
  MIDDLEWARES,
  ROUTES,
} from "@/utils/consts";
import { RouteLocationNormalized } from "vue-router";

export const walletsRoute = [
  {
    path: ROUTES.WALLET.path,
    component: () => import("@/pages/wallet.vue"),
    children: [
      {
        path: "",
        name: ROUTES.WALLET.name,
        component: () => import("@/pages/wallet/index.vue"),
        meta: {
          middlewares: { [MIDDLEWARES.PROTECTED]: true },
          displayName: ROUTES.WALLET.displayName,
        },
        children: [
          {
            path: "deposit/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            props: {
              backPath: "/wallets",
            },
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/select-category.vue"),
                props: {
                  action: "deposit",
                },
              },
              {
                path: "local",
                component: () =>
                  import("@/pages/wallet/actions/deposit/fiat/index.vue"),
                props: {
                  backPath: `/wallets`,
                },
              },
              {
                path: ":category",
                component: () =>
                  import(
                    "@/pages/wallet/actions/deposit/select-wallet-wrapper.vue"
                  ),
                props: {
                  allowedAssets: ALLOWED_CURRENCIES_FOR_DEPOSIT,
                },
              },
              {
                path: ":category/:asset",
                component: () =>
                  import("@/pages/wallet/actions/deposit/deposit-wrapper.vue"),
                props: {
                  backPath: "/wallets",
                },
              },
              {
                path: ":category/:asset/deposit/local/mobile-money",
                component: () =>
                  import(
                    "@/pages/wallet/actions/deposit/fiat/mobile-money/SingleMobileMoneyPage.vue"
                  ),
                props: {
                  backPath: `/wallets`,
                },
              },
            ],
          },
          {
            path: "withdraw/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            props: {
              backPath: "/wallets",
            },
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/withdraw/select-category.vue"),
                props: {
                  action: "withdraw",
                  backPath: "/wallets",
                },
              },
              {
                path: ":category(crypto|fiat)",
                component: () =>
                  import(
                    "@/pages/wallet/actions/withdraw/select-sub-category.vue"
                  ),
                props: {
                  action: "withdraw",
                  parentRoute: "withdraw",
                  backPath: "/wallets/withdraw",
                },
              },
              {
                path: ":category(crypto)/:method(internal|external)",
                component: () =>
                  import(
                    "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
                  ),
              },
              {
                path: ":category(fiat)/:method(banks|momo|apex)",
                component: () =>
                  import(
                    "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
                  ),
              },
              {
                path: ":category(crypto)/:method(internal|external)/:asset",
                component: () =>
                  import("@/pages/wallet/actions/withdraw/withdrawal-mode.vue"),
                props: (route: RouteLocationNormalized) => ({
                  backPath: "/wallets",
                  selectWalletPath: `/wallets/withdraw/${route.params.category}`,
                }),
              },
              {
                path: ":category(fiat)/:method(banks|momo|apex)/:asset",
                component: () =>
                  import("@/pages/wallet/actions/withdraw/withdrawal-mode.vue"),
                props: (route: RouteLocationNormalized) => ({
                  backPath: "/wallets",
                  selectWalletPath: `/wallets/withdraw/${route.params.category}`,
                }),
              },
            ],
          },
          {
            path: "swap/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/trade/trade-wrapper.vue"),
              },
            ],
            props: {
              backPath: ROUTES.WALLET.path,
            },
          },
        ],
      },
      {
        path: ROUTES.WALLET.ID.path,
        name: ROUTES.WALLET.ID.name,
        component: () => import("@/pages/wallet/show.vue"),
        meta: {
          displayName: ROUTES.WALLET.ID.displayName,
          middlewares: { [MIDDLEWARES.PROTECTED]: true },
        },
        children: [
          {
            path: ROUTES.WALLET.ID.DETAILS.path,
            name: ROUTES.WALLET.ID.DETAILS.name,
            component: () => import("@/pages/transactions/show.vue"),
            meta: {
              keepPosition: true,
            },
          },
          {
            path: "deposit/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            props: {
              backPath: "",
            },
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/deposit/deposit-wrapper.vue"),
                props: {
                  showBackButton: false,
                  backPath: "/wallets",
                },
              },
              {
                path: "local",
                component: () =>
                  import("@/pages/wallet/actions/deposit/fiat/index.vue"),
                props: {
                  showBackButton: false,
                  backPath: `/wallets`,
                },
              },
              {
                path: "local/mobile-money",
                component: () =>
                  import(
                    "@/pages/wallet/actions/deposit/fiat/mobile-money/SingleMobileMoneyPage.vue"
                  ),
                props: {
                  backPath: `/wallets`,
                },
              },
            ],
          },
          {
            path: "withdraw/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            props: {},
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/withdraw/SingleWrapper.vue"),
                props: {
                  backPath: "/wallets",
                },
              },
            ],
          },
          {
            path: "trade/",
            component: () => import("@/pages/wallet/actions/index.vue"),
            props: {
              backPath: "",
            },
            children: [
              {
                path: "",
                component: () =>
                  import("@/pages/wallet/actions/trade/trade-wrapper.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                },
                props: {
                  backPath: "",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
